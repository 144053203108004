import FooterPortal from "../FooterPortal";

export default function TermsFooter() {
	return (
		<FooterPortal>
			<div className="w-full p-4 text-center text-customGray font-medium text-sm 3xl:text-[0.9vw]">
				<div className="flex flex-col justify-center items-center">
					<img className="h-6 w-auto 3xl:h-12" src="/shocknet-logo-pill.png" alt="shocknet" />
					<span>
						<a className="text-green-500 hover:cursor-pointer hover:underline" href="https://docs.shock.network" target="_blank" rel="noreferrer">
							Docs
						</a>
						&nbsp;and&nbsp;
						<a className="text-green-500 hover:cursor-pointer hover:underline" href="https://docs.shock.network" target="_blank" rel="noreferrer">
							Terms
						</a>
					</span>
				</div>
			</div>
		</FooterPortal>
	);
}