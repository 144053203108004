interface Props {
	children: React.ReactNode
}
const Button = ({ children, className, ...props }: Props & React.HTMLAttributes<HTMLButtonElement>) => {
	return (
		<button
			{...props}
			className={`text-center font-medium shadow-md
				bg-green-500 hover:bg-green-600 text-white
				active:bg-green-700 rounded-md 3xl:rounded-xl px-10 py-4
			${className}`}>
			{children}
		</button>
	)
}

export default Button