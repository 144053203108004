import React from "react";
import { Link } from "react-router-dom";
import * as Icons from "../../iconLibrary/svgIconLibrary";
import Button from "../../components/Button";
import TokenManagementImage from "../../images/token-management.png"
import TermsFooter from "../../components/Footers/TermsFooter";

const Home: React.FC = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center px-6 mb-6 3xl:text-[1.2vw]">
        <div className="text-2xl 3xl:text-[3.5vw] 3xl:leading-[4vw] text-center font-medium mt-12">Nostr Identity Solved</div>
        <p className="text-customGray font-medium text-left my-6">
          Sanctum provides everything you need to secure and manage Nostr
          identity keys.
        </p>
        <div className="flex items-center justify-between gap-3">
          <div className="flex flex-col items-start gap-2">
            <FeatureLine Icon={Icons.EasyCloud} text="Easy Cloud Management" />
            <FeatureLine Icon={Icons.Delegation} text="Team Delegation" />
            <FeatureLine Icon={Icons.Device} text="Device Level Auditing" />
            <FeatureLine Icon={Icons.Security} text="Unrivaled Security" />
            <FeatureLine Icon={Icons.Trustless} text="Trustless Optionality" />
          </div>
          <img className="w-52 h-auto lg:w-64 xl:w-96 3xl:w-[40vw] 3xl:max-w-[600px] drop-shadow-custom" src={TokenManagementImage} alt="token-management" />
        </div>
        <Link to="/auth/email" className="my-4">
          <Button className="
            text-base 3xl:text-[1.8vw] 3xl:px-[3vw] 3xl:py-[1.5vw]"
          >
            Sign-Up or Sign-In
          </Button>
        </Link>
        <div className="w-full border border-gray-400 rounded-md flex flex-col justify-center items-center p-6 gap-2">
          <span className="font-medium">Hey Builders,</span>
          <span><Icons.Builder /></span>
          <div className="flex flex-col justify-center items-center text-customGray">
            <span>Deploy faster,</span>
            <span>protect your users, and your reputation,</span>
            <span>with our <span className="text-gray-200 font-medium">NIP07 auth widget.</span></span>
          </div>
        </div>
        <a className="mt-2 text-green-500 hover:cursor-pointer hover:underline" href="https://github.com/orgs/shocknet/discussions" target="_blank" rel="noreferrer">
          Contact Us 🤙
        </a>
        <div className="flex flex-col justify-center items-center gap-2 mt-12">
          <span className="text-md">How it works basically,</span>
          <div className="w-full border border-gray-400 rounded-md flex flex-col justify-center items-center p-6 gap-2 text-customGray">
            <HowItWorksGroup Icon={Icons.RequestAccess} text="App widget requests access" />
            <HowItWorksGroup Icon={Icons.Prompt} text="User approves prompt" />
            <HowItWorksGroup Icon={Icons.Signer} text="Signer issues access token" />
            <HowItWorksGroup Icon={Icons.Operations} text="Token requests operation" />
            <HowItWorksGroup Icon={Icons.SignerEvent} text="Sanctum verifies, signer emits the event" />
          </div>
          <span className="text-md">Self host your signer, or use our private cloud.</span>
        </div>
      </div>
      <TermsFooter />
    </>
  );
};

export default Home;

const FeatureLine = ({ Icon, text }: { Icon: React.ComponentType<{ className?: string }>, text: string }) => (
  <div className="flex justify-center items-center">
    <span className="mr-2 mb-1"><Icon className="w-8 h-8 3xl:w-[2.5vw] 3xl:h-[2.5vw]" /></span>
    <span className="text-md font-medium">{text}</span>
  </div>
)

const HowItWorksGroup = ({ Icon, text }: { Icon: React.ComponentType<{ className?: string }>, text: string }) => (
  <div className="flex flex-col gap-1 justify-center items-center">
    <span>{text}</span>
    <span><Icon className="w-8 h-8 3xl:w-[2.5vw] 3xl:h-[2.5vw]" /></span>
  </div>
)
