import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';
import * as Icon from '../../iconLibrary/svgIconLibrary';
import { formatTime } from '../../utils/utility';
import SanctumClient from '../../api';
import { GetAuthRequestInfoResponse } from '../../api/autogenerated/types';
import VerificationInput from 'react-verification-input';
import Button from '../../components/Button';
import { ErrorCode } from '../../utils/errors';
import { useDispatch } from '../../redux';
import { CheckSession } from '../../redux/userSlice';
import Page from '../../layout/page';



const Verify: React.FC = () => {
	const dispatch = useDispatch();

	const { submitToken, requestToken } = useParams() as { submitToken: string, requestToken?: string };


	const navigate: NavigateFunction = useNavigate();


	const [authInfo, setAuthInfo] = useState<GetAuthRequestInfoResponse>()

	const [verifyCode, setVerifyCode] = useState('');
	const [seconds, setSeconds] = useState(0);
	const [timerEnded, setTimerEnded] = useState(false)
	const timeout: NodeJS.Timeout = setTimeout(() => null, 500);
	const timeoutRef = useRef<NodeJS.Timeout>(timeout);

	useEffect(() => {
		const fetchAuthInfo = async () => {
			const handler = SanctumClient.getInstance();
			try {
				const res = await handler.GetAuthRequestInfo(submitToken, requestToken);
				setAuthInfo(res);
			} catch (err) {
				const message = err instanceof Error ? err.message : ErrorCode.UNKNOWN_ERROR
				switch (message) {
					case ErrorCode.OTP_NOT_FOUND_OR_EXPIRED:
						toast.error(<Toast title="Verification Error" message="OTP not found or expired. Please start the flow again" />)
						break;
					case ErrorCode.REQUEST_TOKEN_NOT_FOUND_OR_EXPIRED:
						toast.error(<Toast title="Verification Error" message="The associated request token was not found or is expired." />);
						break;
					default:
						toast.error(<Toast title='Unknown Error' message={message} />);
				}
				navigate("/", { replace: true });
			}
		}
		fetchAuthInfo();
	}, [submitToken, requestToken, navigate])


	// countdowntimer stuff
	useEffect(() => {
		if (!authInfo) return;
		clearInterval(timeoutRef.current);
		const now = Date.now();
		const expiresIn = Math.round((authInfo.expires_at - now) / 1000);
		setTimerEnded(false);
		setSeconds(expiresIn)
		timeoutRef.current = setInterval(() => {
			const secondsLeft = Math.round((authInfo.expires_at - Date.now()) / 1000);
			console.log({ secondsLeft })
			if (secondsLeft < 0) {
				clearInterval(timeoutRef.current)
				setTimerEnded(true)
				return;
			}
			setSeconds(secondsLeft)
		}, 1000);
		return () => clearInterval(timeoutRef.current)
	}, [authInfo])







	const handleSubmit = useCallback(async () => {
		const handler = SanctumClient.getInstance();
		if (verifyCode.length !== 6) {
			return toast.error(<Toast title='Code is invalid' message='Input a 6 digit code.' />);
		}
		try {
			const res = await handler.RequestCallback(submitToken, verifyCode);
			await dispatch(CheckSession());
			if (requestToken && res.auth) {
				navigate(`/auth/confirmation/${requestToken}`, { replace: true });
			} else {
				navigate("/manage", { replace: true })
			}
		} catch (err) {
			const message = err instanceof Error ? err.message : ErrorCode.UNKNOWN_ERROR
			switch (message) {
				case ErrorCode.OTP_NOT_FOUND_OR_EXPIRED:
					toast.error(<Toast title="Verification Error" message="OTP not found or expired. Please start the flow again" />)
					navigate("/", { replace: true });
					break;
				case ErrorCode.OTP_INVALID:
					toast.error(<Toast title="Verification Error" message="Wrong verification code. Please input the correct code we sent to your email." />)
					break;
				case ErrorCode.OTP_ATTEMPTS_EXCEEDED:
					toast.error(<Toast title="Verification Error" message="Too many wrong verification attempts. Please start the flow again." />);
					navigate("/", { replace: true });
					break;
				default:
					toast.error(<Toast title='Unknown Error' message={message} />);
			}
		}
	}, [navigate, requestToken, submitToken, verifyCode, dispatch])

	if (!authInfo) {
		return <div>Loading...</div>
	}

	return (

		<Page title="Verification">
			<div className="flex flex-col justify-center items-center my-6">
				{
					timerEnded
						?
						<div>Timer has ended</div>
						:
						<>
							<p className="text-customGray leading-10  font-medium text-center whitespace-pre-line">
								Please enter the 6 digits code we sent to {authInfo.email}.
							</p>
							<VerificationInput
								onChange={(e) => setVerifyCode(e)}
								length={6}
								validChars='0-9'


								classNames={{
									container: "mt-12 3xl:w-[28vw] 3xl:h-[4.1vw] 3xl:gap-3",
									character: "rounded-md shadow-md outline-0 bg-transparent text-green-500 text-center flex justify-center items-center text-xl 3xl:text-[1.4vw] ",
									characterInactive: "border-gray-500",
									characterSelected: "border-green-500",
									characterFilled: "border-green-500"

								}}
							/>
							<div className="flex justify-center items-center my-6">
								<Icon.SandClock className='3xl:w-[1.5vw] 3xl:h-[1.5vw]' />
								<span className="ml-2">{formatTime(seconds)}</span>
							</div>
							<div >
								<Button onClick={handleSubmit}>
									Submit
								</Button>
							</div>
						</>
				}

			</div>
		</Page>

	);
}



export default Verify;
