import React from 'react';
import { NavigateFunction, useNavigate, Link } from "react-router-dom";
import OutlinedButton from '../../components/OutlinedButton';
import { useSelector } from '../../redux';
import { formatIdentifier } from '../../utils/utility';



export const Header: React.FC = () => {
	const navigate: NavigateFunction = useNavigate();
	const user = useSelector(state => state.user)

	return (
		<>
			<div className="cursor-pointer" onClick={() => navigate('/')}>
				<img className="h-4 md:h-6 3xl:h-[1.7vw] w-auto" src='/logo.png' alt='logo' />
			</div>
			<div className="3xl:text-[1.1vw]">

				{
					user.userInfo.identifier
						?
						<Link to="/manage">
							<span className="text-green-500 cursor-pointer hover:underline">{formatIdentifier(user.userInfo.identifier)}</span>
						</Link>
						:
						<Link to="/auth/email">
							<OutlinedButton>
								Sign In
							</OutlinedButton>
						</Link>
				}
			</div>


		</>
	)
}
