import FooterPortal from "../FooterPortal";

export default function FlowFooter() {
	return (
		<FooterPortal>
			<div className="w-full p-4 text-center text-customGray font-medium text-sm 3xl:text-[0.9vw]">
				By proceeding you acknowledge that this is bleeding-edge software, and agree to the providers <a href="https://docs.shock.network/terms" target="_blank" rel="noreferrer" className="text-green-500 hover:cursor-pointer hover:underline">terms</a> regarding any services herein.
			</div>
		</FooterPortal>
	);
}