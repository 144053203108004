import React from "react";
import { Header } from "./header";
import { Outlet } from "react-router-dom";


interface LayoutProps {
  children?: React.ReactNode,
}

export const Layout: React.FC<LayoutProps> = (): JSX.Element => {


  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <header className="container px-3 md:px-12 py-4 md:py-8">
        <div className="w-full mx-auto flex items-center justify-between">
          <Header />
        </div>
      </header>
      <main className="flex-grow container mx-auto my-6 flex flex-col items-center justify-center">
        <Outlet />
      </main>
      <footer id="sanctum-footer" className="w-full" />
    </div>
  )
}

export default Layout;
