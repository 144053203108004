import React, { useCallback, useState } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from '../../components/Toast';
import { isValidEmail } from '../../utils/utility';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import SanctumClient from '../../api';
import Page from '../../layout/page';
import { ErrorCode } from '../../utils/errors';
import FlowFooter from '../../components/Footers/FlowFooter';


const Authenticate: React.FC = () => {
	const { requestToken } = useParams() as { requestToken?: string };

	const navigate = useNavigate();
	const [email, setEmail] = useState("");


	const handleSendCode = useCallback(async () => {
		const handler = SanctumClient.getInstance();
		if (!isValidEmail(email)) {
			return toast.error(<Toast title='The email is invalid' message='Please input a valid email' />);
		}
		try {
			const res = await handler.AuthEmail(email, requestToken)
			navigate(requestToken ? `/auth/verify/${res.submit_token}/${requestToken}` : `/auth/verify/${res.submit_token}`, { replace: true });
		} catch (err) {
			const message = err instanceof Error ? err.message : ErrorCode.UNKNOWN_ERROR
			switch (message) {
				case ErrorCode.INVALID_INPUT:
					toast.error(<Toast title='The email is invalid' message='Please input a valid email' />);
					setEmail("");
					break;
				case ErrorCode.REQUEST_TOKEN_NOT_FOUND_OR_EXPIRED:
					toast.error(<Toast title="The request token was not found or is expired" message="Please retry the auth flow from the start" />);
					break;
				default:
					toast.error(<Toast title='Unknown Error' message={message} />);
			}
		}
	}, [email, requestToken, navigate])






	return (
		<>
			<Page title="Auth">
				<div className="flex flex-col justify-center items-center space-y-2">
					<span className="text-gray-200 font-medium">Get a login code emailed to you</span>
					<TextInput placeholder="Enter an email address" onChange={(e) => setEmail(e.target.value.trim())} value={email} />
					<Button onClick={handleSendCode}>
						Send Code
					</Button>
				</div>
				<div className="flex items-center my-12 w-full">
					<div className="flex-grow border-t border-gray-300"></div>
					<span className="px-4 text-gray-500 font-semibold">OR</span>
					<div className="flex-grow border-t border-gray-300"></div>
				</div>
				<div className="text-center">
					<Link className="text-green-500 hover:cursor-pointer hover:underline" to={requestToken ? `/auth/nsec/${requestToken}` : "/auth/nsec"}>
						Already have an NSEC for Nostr?
					</Link>
				</div>
			</Page>
			<FlowFooter />
		</>
	);
}

export default Authenticate;